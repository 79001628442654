/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { FC, useState, useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import Badge from '../common/elements/Badge';
import { ImageGallery } from '../../ui/imageGallery';
import { FullWidthRioSlider } from '../common/elements/Slider';
import Button from '../../ui/button';
import pages from '../../../utils/pages';
import { analyticsInit, segmentTrackRequest } from '../ssu/ssu-utils.js';

analyticsInit();
const { getPageUrl } = pages;

const RoiResults: FC<{
  results?: any;
  lang?: string;
}> = ({ results, lang }) => {
  console.log(lang);
  const { t } = useTranslation('roi');
  const cards: any = t('cards', {
    returnObjects: true,
  });

  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: lang === 'de-CH' ? 'CHF' : 'EUR',
  });

  useEffect(() => {
    segmentTrackRequest('roi_showing_results');
  }, []);

  const r = results;

  const total_savings =
    parseInt(r.revenue_from_new_customers_improvement.value, 10) +
    parseInt(r.revenue_from_additional_capacity.value, 10) +
    parseInt(r.revenue_per_month_improvement.value, 10);

  return (
    <>
      <div className="roi-slider-wrap">
        <FullWidthRioSlider tracker={segmentTrackRequest}>
          {/* Reduce missed appointments with automatic reminders */}
          <div className="is-centered columns less_missed_appointments">
            <Badge className="roi-badge">{t('yourPontential')}</Badge>
            <h2
              className="title-medium"
              dangerouslySetInnerHTML={{ __html: cards.card_1.title }}
            />
            {!isMobileOnly && (
              <div className="modal-center-image">
                <ImageGallery
                  name={t('cards.card_1.image')}
                  alt=" "
                  className="top-image centered-image  "
                  width="340"
                />
              </div>
            )}

            <div className="is-centered columns  ">
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_1.left}</h3>
                  {t('withoutShore')}{' '}
                  <span className="roi-right">
                    {Math.round(r.noshows_per_month_new.value)}
                  </span>
                  <br />
                  {t('withShore')}
                  <span className="info-icon">
                    i<legend> {t('disclaimer')}</legend>
                  </span>{' '}
                  <span className="roi-right">
                    {Math.floor(r.noshows_per_month_int_with_shore.value)}
                  </span>
                  <br />
                  <span className="roi-highlight  has-text-success">
                    {Math.floor(r.noshows_per_month_improvement.value)}{' '}
                  </span>
                </div>
              </div>
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card ">
                  <h3 className="mb-2 title-small">{cards.card_1.right}</h3>
                  {t('withoutShore')}
                  <span className="roi-right">
                    {formatter.format(
                      Math.floor(r.revenue_per_month_without_shore.value)
                    )}
                  </span>
                  <br />
                  {t('withShore')}
                  <span className="info-icon">
                    i<legend> {t('disclaimer')}</legend>
                  </span>{' '}
                  <span className="roi-right">
                    {formatter.format(
                      Math.floor(r.revenue_per_month_with_shore.value)
                    )}{' '}
                  </span>
                  <br />
                  <span className="roi-highlight  has-text-success">
                    +
                    {formatter.format(
                      Math.floor(r.revenue_per_month_improvement.value)
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              <Button
                className="mt-5"
                size="large"
                primary
                to={`${
                  getPageUrl('ssu', 'de') && getPageUrl('ssu', 'de').url
                }?roi=true`}
              >
                {t('cards.card_5.ctaText')}
              </Button>
              <p>
                <Link
                  className="inline-link is-size-7 mt-3"
                  to={
                    getPageUrl('demo', t('key')) &&
                    getPageUrl('demo', t('key')).url
                  }
                >
                  {t('cards.card_5.ctaAlternative')}
                </Link>
              </p>
            </div>
            {isMobileOnly && (
              <div className="modal-center-image mt-5">
                <ImageGallery
                  name={t('cards.card_1.image').concat('_mobile')}
                  alt=" centeterd roi preview"
                  className="top-image centered-image  "
                  width="340"
                />
              </div>
            )}
          </div>

          {/* Reduce your administrative workload for staff and customers */}
          <div className="is-centered columns less_workload">
            <Badge className="roi-badge">{t('yourPontential')}</Badge>
            <h2
              className="title-medium"
              dangerouslySetInnerHTML={{ __html: cards.card_2.title }}
            />

            {!isMobileOnly && (
              <div className="modal-center-image">
                <ImageGallery
                  name={t('cards.card_2.image')}
                  alt=" centeterd roi preview"
                  className="top-image centered-image  "
                  width="340"
                />
              </div>
            )}
            <div className="is-6 column roi-result-wrap">
              <div className="roi-result-card">
                <h3 className="mb-2 title-small">{cards.card_2.left}</h3>
                {t('withoutShore')}
                <span className="roi-right">
                  {r.time_expenditure_per_month_without_shore_minutes.value}{' '}
                  {t('hours')}
                </span>
                <br />
                {t('withShore')}
                <span className="info-icon">
                  i<legend> {t('disclaimer')}</legend>
                </span>{' '}
                <span className="roi-right">
                  {Math.round(
                    r.time_expenditure_per_month_with_shore_minutes.value
                  )}{' '}
                  {t('hours')}
                </span>
                <br />
                <span className="roi-highlight has-text-success">
                  -{Math.round(r.time_saving_per_month_minutes.value)}{' '}
                  {t('hours')}{' '}
                </span>
              </div>
            </div>
            <div className="is-6 column roi-result-wrap">
              <div className="roi-result-card">
                <h3 className="mb-2 title-small">{cards.card_2.right}</h3>
                {t('withoutShore')}
                <span className="roi-right">0</span>
                <br />
                {t('withShore')}
                <span className="info-icon">
                  i<legend> {t('disclaimer')}</legend>
                </span>{' '}
                <span className="roi-right">
                  {formatter.format(r.revenue_from_additional_capacity.value)}{' '}
                </span>
                <br />
                <span className="roi-highlight has-text-success">
                  +{formatter.format(r.revenue_from_additional_capacity.value)}
                </span>
              </div>
            </div>

            <div className="has-text-centered">
              <Button
                className="mt-5"
                size="large"
                primary
                to={`${
                  getPageUrl('ssu', 'de') && getPageUrl('ssu', 'de').url
                }?roi=true`}
              >
                {t('cards.card_5.ctaText')}
              </Button>
              <p>
                <Link
                  className="inline-link is-size-7 mt-3"
                  to={
                    getPageUrl('demo', t('key')) &&
                    getPageUrl('demo', t('key')).url
                  }
                >
                  {t('cards.card_5.ctaAlternative')}
                </Link>
              </p>
            </div>

            {isMobileOnly && (
              <div className="modal-center-image mt-5">
                <ImageGallery
                  name={t('cards.card_2.image').concat('_mobile')}
                  alt=" centeterd roi preview"
                  className="top-image centered-image  "
                  width="340"
                />
              </div>
            )}
          </div>

          {/* Win more new customers through digital appointment booking */}
          <div className="is-centered columns new_appointments">
            <Badge className="roi-badge">{t('yourPontential')}</Badge>
            <h2
              className="title-medium"
              dangerouslySetInnerHTML={{ __html: cards.card_3.title }}
            />

            {!isMobileOnly && (
              <div className="modal-center-image">
                <ImageGallery
                  name={t('cards.card_3.image')}
                  alt=" centeterd roi preview"
                  className="top-image centered-image  "
                  width="340"
                />
              </div>
            )}
            <div className="is-6 column roi-result-wrap">
              <div className="roi-result-card">
                <h3 className="mb-2 title-small">{cards.card_3.left}</h3>
                {t('withoutShore')}
                <span className="roi-right">
                  {r.new_customers_without_shore.value}
                </span>
                <br />
                {t('withShore')}
                <span className="info-icon">
                  i<legend> {t('disclaimer')}</legend>
                </span>{' '}
                <span className="roi-right">
                  {Math.round(r.new_customers_with_shore.value * 100) / 100}
                </span>
                <br />
                <span className="roi-highlight has-text-success">
                  +
                  {Math.round(
                    r.new_customers_with_shore_improvement_percent.value
                  )}
                  %
                </span>
              </div>
            </div>
            <div className="is-6 column roi-result-wrap">
              <div className="roi-result-card">
                <h3 className="mb-2 title-small">{cards.card_3.right}</h3>
                {t('withoutShore')}
                <span className="roi-right">
                  {formatter.format(
                    r.revenue_from_new_customers_without_shore.value
                  )}
                </span>
                <br />
                {t('withShore')}
                <span className="info-icon">
                  i<legend> {t('disclaimer')}</legend>
                </span>{' '}
                <span className="roi-right">
                  {r &&
                    r.revenue_from_new_customers &&
                    formatter.format(r.revenue_from_new_customers.value)}
                </span>
                <br />
                <span className="roi-highlight has-text-success">
                  +
                  {formatter.format(
                    r.revenue_from_new_customers_improvement.value
                  )}{' '}
                </span>
              </div>
            </div>

            <div className="has-text-centered">
              <Button
                className="mt-5"
                size={isMobileOnly ? 'default' : 'large'}
                primary
                to={`${
                  getPageUrl('ssu', 'de') && getPageUrl('ssu', 'de').url
                }?roi=true`}
              >
                {t('cards.card_5.ctaText')}
              </Button>
              <p>
                <Link
                  className="inline-link is-size-7 mt-3"
                  to={
                    getPageUrl('demo', t('key')) &&
                    getPageUrl('demo', t('key')).url
                  }
                >
                  {t('cards.card_5.ctaAlternative')}
                </Link>
              </p>
            </div>

            {isMobileOnly && (
              <div className="modal-center-image mt-5">
                <ImageGallery
                  name={t('cards.card_3.image').concat('_mobile')}
                  alt=" centeterd roi preview"
                  className="top-image centered-image"
                  width="340"
                />
              </div>
            )}
          </div>

          {/* Overview all improvements */}
          <div className="is-centered columns overview">
            <Badge className="roi-badge">{t('yourPontential')}</Badge>
            <h2
              className="title-medium"
              dangerouslySetInnerHTML={{
                __html: `${
                  cards.card_4.title
                }<span class='has-text-purple'> ${formatter.format(
                  total_savings
                )}</span> ${cards.card_4.title2}`,
              }}
            />
            <div className="is-centered columns row ">
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_1.left}</h3>
                  <span className="roi-highlight  has-text-success">
                    {Math.floor(r.noshows_per_month_improvement.value)}{' '}
                  </span>
                </div>
              </div>
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_1.right}</h3>
                  <span className="roi-highlight  has-text-success">
                    +
                    {formatter.format(
                      Math.floor(r.revenue_per_month_improvement.value)
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="is-centered columns row">
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_2.left}</h3>
                  <span className="roi-highlight has-text-success">
                    -{Math.round(r.time_saving_per_month_minutes.value)}{' '}
                    {t('hours')}{' '}
                  </span>
                </div>
              </div>
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_2.right}</h3>
                  <span className="roi-highlight has-text-success">
                    +
                    {formatter.format(r.revenue_from_additional_capacity.value)}
                  </span>
                </div>
              </div>
            </div>

            <div className="is-centered columns  row">
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_3.left}</h3>
                  <span className="roi-highlight has-text-success">
                    +
                    {Math.round(
                      r.new_customers_with_shore_improvement_percent.value
                    )}
                    %
                  </span>
                </div>
              </div>
              <div className="is-6 column roi-result-wrap">
                <div className="roi-result-card">
                  <h3 className="mb-2 title-small">{cards.card_3.right}</h3>
                  <span className="roi-highlight has-text-success">
                    +
                    {formatter.format(
                      r.revenue_from_new_customers_improvement.value
                    )}{' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="has-text-centered">
              <Button
                className="mt-5"
                size={isMobileOnly ? 'default' : 'large'}
                primary
                to={`${
                  getPageUrl('ssu', 'de') && getPageUrl('ssu', 'de').url
                }?roi=true`}
              >
                {t('cards.card_5.ctaText')}
              </Button>
              <p>
                <Link
                  className="inline-link is-size-7 mt-3"
                  to={
                    getPageUrl('demo', t('key')) &&
                    getPageUrl('demo', t('key')).url
                  }
                >
                  {t('cards.card_5.ctaAlternative')}
                </Link>
              </p>
            </div>

            {isMobileOnly && (
              <div className="modal-center-image mt-5">
                <ImageGallery
                  name={t('cards.card_3.image').concat('_mobile')}
                  alt=" centeterd roi preview"
                  className="top-image centered-image"
                  width="340"
                />
              </div>
            )}
          </div>

          {/* CTA SLIDE: GET YOUR FREE TRIAL NOW"!!! */}
          <div className="is-centered columns free_trial">
            <Badge className="roi-badge">{t('cards.card_5.pill')}</Badge>
            <h2
              className="title-medium"
              dangerouslySetInnerHTML={{ __html: cards.card_5.title }}
            />

            {!isMobileOnly && (
              <div className="modal-center-image">
                <div className="trustline columns m-5 is-justify-content-center">
                  <div className="column has-text-centered res-card">
                    <ImageGallery
                      alt=""
                      className="mb-3 is-hidden-mobile"
                      name="bell_on-black"
                      maxWidth="80"
                      height="80"
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'cards.card_5.trustlines.trustline_1.text'
                        ).replace(' ', '<br>'),
                      }}
                    />
                  </div>
                  <div className="column has-text-centered res-card ">
                    <ImageGallery
                      alt=""
                      className="mb-3 is-hidden-mobile"
                      name="calendar_check-black"
                      maxWidth="80"
                      height="80"
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'cards.card_5.trustlines.trustline_2.text'
                        ).replace(' ', '<br>'),
                      }}
                    />
                  </div>

                  <div className="column has-text-centered res-card ">
                    <ImageGallery
                      alt=""
                      className="mb-3 is-hidden-mobile"
                      name="heart_1-black"
                      maxWidth="80"
                      height="80"
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'cards.card_5.trustlines.trustline_3.text'
                        ).replace(' ', '<br>'),
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="column roi-result-wrap wide">
              <div className="wide">
                <h3 className="mt-5 ">{cards.card_5.subtitle}</h3>
                <Button
                  className="is-block my-5"
                  size="large"
                  primary
                  to={`${
                    getPageUrl('ssu', 'de') && getPageUrl('ssu', 'de').url
                  }?roi=true`}
                >
                  {t('cards.card_5.ctaText')}
                </Button>
                <Link
                  className="inline-link"
                  to={
                    getPageUrl('demo', t('key')) &&
                    getPageUrl('demo', t('key')).url
                  }
                >
                  {t('cards.card_5.ctaAlternative')}
                </Link>
              </div>
            </div>
            {isMobileOnly && (
              <div className="modal-center-image">
                <div className="trustline mt-2">
                  <div className="is-flex is-align-items-center p-2">
                    <ImageGallery
                      alt=""
                      className="mr-3 pt-2"
                      name="circle_bell_purple"
                      maxWidth="30"
                      height="30"
                    />
                    <span>{t('cards.card_5.trustlines.trustline_1.text')}</span>
                  </div>
                  <div className="is-flex is-align-items-center p-2">
                    <ImageGallery
                      alt=""
                      className="mr-3 pt-2"
                      name="circle_cal_purple"
                      maxWidth="30"
                      height="30"
                    />
                    <span>{t('cards.card_5.trustlines.trustline_2.text')}</span>
                  </div>
                  <div className="is-flex is-align-items-center p-2">
                    <ImageGallery
                      alt=""
                      className="mr-3 pt-2"
                      name="circle_heart_purple"
                      maxWidth="30"
                      height="30"
                    />
                    <span>{t('cards.card_5.trustlines.trustline_3.text')}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </FullWidthRioSlider>
      </div>
      <div className="disclaimer-footer has-text-centered p-2 pb-5">
        <p className=" ">{t('disclaimer')}</p>
      </div>
    </>
  );
};

RoiResults.defaultProps = {
  results: '',
  lang: 'de',
};

export default RoiResults;

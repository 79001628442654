/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { analyticsInit, segmentTrackRequest } from '../ssu/ssu-utils.js';

analyticsInit();

function deferHSinit(method) {
  // @ts-ignore
  if (window.hbspt) {
    method();
  } else {
    setTimeout(() => {
      deferHSinit(method);
    }, 50);
  }
}

function deferFormReady(method) {
  // @ts-ignore
  const form = document.getElementById('hubspotForm');
  if (form) {
    const submitWrap = form.getElementsByClassName('hs_submit hs-submit')[0];
    if (submitWrap) {
      method();
    } else {
      setTimeout(() => {
        deferFormReady(method);
      }, 50);
    }
  }
}

function showNextFields(current, max, fields, step) {
  let reachedEnd = false;
  if (current >= max) {
    current = max;
    reachedEnd = true;
  }

  const progress = Math.floor((current / max) * 100);
  makeProgress(progress);
  for (let i = 0; i < fields.length; i++) {
    if (i < current) fields[i].style.display = 'block';
  }
  return reachedEnd;
}
function makeProgress(progress) {
  let i = 1;
  const progress_wrap = document.getElementById('progress_bar');
  const progress_bar = progress_wrap.getElementsByClassName('progress')[0];
  let width = 1;
  const id = setInterval(frame, 10);
  // eslint-disable-next-line no-inner-declarations

  function frame() {
    if (width >= progress) {
      clearInterval(id);
      i = 0;
    } else {
      width++;
      // @ts-ignore
      progress_bar.style.width = `${width}%`;
    }
  }
}

const RoiForm: FC<{
  results?: any;
  success?: boolean;
  open?: boolean;
  thanks?: boolean;
  lang: string;
  single?: boolean;
}> = ({ results, success, open, thanks, lang, single }) => {
  const [modal_visibility, toogleModal] = useState(thanks);
  const [formVisible, showForm] = useState(open);

  const { t } = useTranslation('roi');

  useEffect(() => {
    if (formVisible && !modal_visibility) segmentTrackRequest('roi_init');
  }, [success]);

  useEffect(() => {
    const nextBtn = document.createElement('button');
    nextBtn.innerHTML = t('next');
    nextBtn.id = 'roiNextBTN';
    nextBtn.type = 'button';
    nextBtn.classList.add('button');

    const progressbar = document.createElement('div');
    const progress = document.createElement('div');
    progressbar.id = 'progress_bar';
    progress.classList.add('progress');
    progressbar.appendChild(progress);

    let stepper = 3;
    const stepsize = 3;
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    let trackerCount = 0;
    deferHSinit(() =>
      // @ts-ignore
      window.hbspt.forms.create({
        portalId: '498216',
        formId: t('hs_formId'), // '5ae36df3-480b-4795-97ef-ac7c646a9b10',
        target: '#hubspotForm',
        locale: lang,
        onFormReady(form) {
          /*   access to form and trigger submit prog. is working
          console.log(form)
          e.preventDefault();
          form.submit(); */
        },
        translations: {
          de: {
            submitText: t('submitText'),
          },
          en: {
            submitText: t('submitText'),
          },
        },
        onFormSubmitted: () => {
          segmentTrackRequest('roi-form-submitted');
        },
      })
    );

    /* 
          hacky way to make hubspot think we are usin jQuery
          and to allow us to hook into *onFormSubmitted* 
      */

    (() => {
      // @ts-ignore
      window.jQuery =
        window.jQuery ||
        function (nodeOrSelector) {
          // @ts-ignore
          if (typeof nodeOrSelector === 'string')
            return document.querySelector(s);
          return nodeOrSelector;
        };
    })();

    const form = document.getElementById('hubspotForm');
    const roi_section = document.getElementById('form-wrapper');
    if (!thanks) {
      deferFormReady(() => {
        if (form) {
          const submitWrap = form.getElementsByClassName(
            'hs_submit hs-submit'
          )[0];
          // @ts-ignore
          if (submitWrap) {
            // @ts-ignore
            submitWrap.firstChild.style.display = 'none';

            const submit = submitWrap.getElementsByClassName('actions')[0];
            // @ts-ignore
            submit.style.display = 'none';
            submitWrap.prepend(progressbar);
            submitWrap.appendChild(nextBtn);
            let n = 3;
            const legends = form.getElementsByClassName('hs-field-desc');
            for (let i = 3; i < legends.length; i++) {
              if (legends[i].innerHTML) {
                const info = document.createElement('span');
                info.classList.add('info-icon');
                info.innerHTML = 'i';
                info.onclick = () => {
                  info.classList.add('active');
                };
                const sib = legends[i].previousElementSibling;
                sib.appendChild(info);
                sib
                  .getElementsByClassName('info-icon')[0]
                  .appendChild(legends[i]);
              }
            }

            let inputs = form.getElementsByClassName('field');
            let max = inputs.length;
            let end = false;

            for (let i = 3; i < inputs.length; i++) {
              // @ts-ignore
              inputs[i].style.display = 'none';
            }

            inputs = form.getElementsByClassName('field');
            max = inputs.length;
            const progress = Math.floor((4 / max) * 100);
            makeProgress(progress);

            nextBtn.onclick = () => {
              stepper += 3;
              n = stepper < max ? stepper : max;
              end = showNextFields(n, max, inputs, stepsize);

              if (end) {
                // @ts-ignore
                submit.style.display = 'block';
                nextBtn.remove();
              }
            };

            for (let i = 0; i < inputs.length; i++) {
              if (inputs[i]) {
                const e = inputs[i].getElementsByClassName('hs-input')[0];
                if (e) {
                  // @ts-ignore
                  const f = () => {
                    if (
                      // @ts-ignore
                      e.value === 'Others (manuel input)' ||
                      // @ts-ignore
                      e.value === 'Others (manual input)'
                    ) {
                      setTimeout(() => {
                        // @ts-ignore
                        const nextSib = this.parentElement.parentElement
                          .nextElementSibling;
                        // @ts-ignore
                        nextSib.getElementsByClassName('hs-input')[0].focus();
                      }, 500);
                    }
                    deferHSinit(() => {
                      inputs = form.getElementsByClassName('field');
                      max = inputs.length;
                    });

                    let numberValidInputs = 0;

                    for (let i = 0; i <= n; i++) {
                      if (inputs[i]) {
                        const e = inputs[i].getElementsByClassName(
                          'hs-input'
                        )[0];
                        // @ts-ignore
                        const { value } = e;
                        if (value) {
                          inputs[i].classList.add('valid');
                          numberValidInputs += 1;
                          if (numberValidInputs === n) {
                            stepper += 3;
                            n = stepper < max ? stepper : max;
                            end = showNextFields(n, max, inputs, stepsize);
                            if (trackerCount < 3) {
                              trackerCount++;
                              segmentTrackRequest('roi-form-step');
                            }

                            if (end) {
                              // @ts-ignore
                              submit.style.display = 'block';
                              nextBtn.remove();
                            }
                          }
                        } else console.log('please fill all fields');
                      }
                    }
                  };
                  // @ts-ignore
                  e.onchange = f;
                  // @ts-ignore
                  // e.onclick = f;
                  // @ts-ignore
                  e.onkeyup = f;
                  // @ts-ignore
                  e.onkeypress = f;
                }
              }
            }
            form.style.display = 'block';
            roi_section.style.opacity = '1';
          }
        }
      });
    } else {
      setTimeout(() => {
        if (form) {
          const submitWrap = form.getElementsByClassName(
            'hs_submit hs-submit'
          )[0];
          if (submitWrap)
            // @ts-ignore
            form.style.display = 'block';
          roi_section.style.opacity = '1';
          // @ts-ignore
          submitWrap.firstChild.style.display = 'none';
          const submit = submitWrap.getElementsByClassName('actions')[0];
          // @ts-ignore
          submit.style.display = 'none';
        }
      }, 2000);
    }
  }, []);

  // @ts-ignore
  const r = results;
  return (
    <>
      <div className="roi-inner-section">
        <h2 className={`title-large ${!single && 'text-black '} `}>
          {t('headline')}
        </h2>
        <p className={` has-text-left   ${!single && 'text-black'}`}>
          {t('description')}
        </p>
        {single}
        {!formVisible && (
          <button
            className=" cta button large exclusive is-centered"
            onClick={() => {
              showForm(true);
              segmentTrackRequest('roi-open-button-clicked');
            }}
            type="button"
            onKeyDown={() => {
              showForm(true);
              segmentTrackRequest('roi-open-button-clicked');
            }}
          >
            {t('discoverMore')}
          </button>
        )}

        <div id="form-wrapper" style={{ opacity: '0' }}>
          <div
            id="hubspotForm"
            className={`${!single && ' text-black'} ${thanks && 'blur_input'}`}
            style={{ display: 'none', margin: 'auto', width: '100%' }}
          />
        </div>

        {modal_visibility && (
          <div className={` text-centered roi thanks  is-active modal`}>
            <div className="modal-content  ">
              <h2 className="title-medium">
                &#128525; &nbsp;&nbsp; &#129395; &nbsp;&nbsp; &#129321;
                <br />
                {t('thanks.title')}
              </h2>
              <p>{t('thanks.p1')}</p>
              <br />
              <p dangerouslySetInnerHTML={{ __html: t('thanks.p2') }} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

RoiForm.defaultProps = {
  success: false,
  open: false,
  results: '',
  thanks: false,
  single: false,
};

export default RoiForm;

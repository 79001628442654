/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import { useEffect, useState, FC } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import RoiResults from '../components/partials/widgets/Roi-Results';
import RoiForm from '../components/partials/widgets/Roi-HS';

let input_string = '';
if (typeof window !== `undefined`) {
  input_string = window.location.href;
}

const IndexPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('ssu_pos');
  const { lang, alternateUrls } = pageContext;
  let IBoolean: boolean;

  const [loading, setLoading] = useState<typeof IBoolean>(true);
  const [loadResults, hasID] = useState<typeof IBoolean>(false);
  const [roiData, setRoiData] = useState<typeof IBoolean>(false);
  const [showThanks, setShowThanks] = useState<typeof IBoolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getLeadData = async (id) => {
    try {
      const response = await fetch(
        `https://shore-web.netlify.app/.netlify/functions/getHSLead`,
        {
          method: 'POST',
          body: JSON.stringify({ id }),
        }
      );

      const answer = await response.json();

      setRoiData(answer.response.properties);

      return null;
    } catch (err) {
      console.log('Error connecting to backend: ', err);
      return err;
    }
  };

  useEffect(() => {
    setLoading(true);
    const url = new URL(input_string);
    const id = url.searchParams.get('id');
    if (id) {
      hasID(true);
      getLeadData(id);
    }
    const thanks = url.searchParams.get('thanks');
    if (thanks) setShowThanks(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  if (loading)
    return (
      <div className="loader-wrapper">
        <BallTriangle color="#00BFFF" height={100} width={300} />
      </div>
    );
  return (
    <Layout
      pageContext={pageContext}
      secondNav
      navClasses="transparent"
      mainClasses=" "
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      {roiData && loadResults && <RoiResults results={roiData} lang={lang} />}
      {!loadResults && <RoiForm lang={lang} thanks={showThanks} single open />}
    </Layout>
  );
};

export default IndexPage;
